exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/articles/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-articles-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nuro-mansion-js": () => import("./../../../src/pages/nuro-mansion.js" /* webpackChunkName: "component---src-pages-nuro-mansion-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-building-template-js": () => import("./../../../src/templates/building-template.js" /* webpackChunkName: "component---src-templates-building-template-js" */),
  "component---src-templates-city-template-js": () => import("./../../../src/templates/city-template.js" /* webpackChunkName: "component---src-templates-city-template-js" */),
  "component---src-templates-pref-template-js": () => import("./../../../src/templates/pref-template.js" /* webpackChunkName: "component---src-templates-pref-template-js" */),
  "component---src-templates-town-template-js": () => import("./../../../src/templates/town-template.js" /* webpackChunkName: "component---src-templates-town-template-js" */)
}

